import styled from "styled-components";
import { 
  DESKTOP_1024,
  DESKTOP_1200,
  MOBILE_460,
  TABLET_992,
  TABLET_800,
  TABLET_850, 
  DESKTOP_1280
} from "../../../styles/globals/sizes";
import { COLORS } from "../../../styles/globals/colors";

export const HeroContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #ececec;
  position: relative;
  background: #ffffff;

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const HeroPicture = styled.picture`
  & > img,
  & > source {
    width: 100%;
    margin-right: 0%;
    object-fit: cover;
  }
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const InternalWrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0;
  position: absolute;
  bottom: 28%;

  @media (max-width: ${DESKTOP_1280}px) {    
    bottom: 18%;
  }

  @media (max-width: ${DESKTOP_1024}px) {    
    bottom: 14%;
  }

  @media (max-width: ${TABLET_992}px) {  
    display: flex;
    position: unset;
  }
`;

export const HeroPictureMobile = styled.div`
  width: 100%;
  display: none;

  @media (max-width: ${TABLET_992}px) {
    display: contents;
  }
`;

export const HeroTextDiv = styled.div`
  width: 100%;
  margin-left: 60px;
  box-sizing: border-box;

  @media (max-width: ${TABLET_992}px) {
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const Title = styled.h1`
  width: 18em;
  color: ${COLORS.redTitle};
  text-align: left;
  font: normal normal 500 40px/47px 'Roboto';
  color: #E0281A;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 54%;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    font: normal normal normal 40px/48px 'Roboto';
    align-items: center;
  }
`;

export const Subtitle = styled.div`
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Paragraph = styled.span`
  width: 48%;
  margin-top: 14px;
  display: flex;
  align-items: center;
  color: ${COLORS.grayText};
  font: normal normal normal 20px/28px 'Roboto';

  @media (max-width: ${DESKTOP_1200}px) {
    width: 54%;
  }

  @media (max-width: ${DESKTOP_1024}px) {
    width: 60%;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 0;
    display: inline;
    font: normal normal normal 22px/28px 'Roboto';
  }
`;

export const Text = styled.span`
  width: 48%;
  margin-top: 14px;
  display: flex;
  align-items: center;
  color: ${COLORS.grayText};
  font: normal normal normal 20px/28px 'Roboto';

  @media (max-width: ${DESKTOP_1200}px) {
    width: 46%;
  }

  @media (max-width: ${DESKTOP_1024}px) {
    width: 48%;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 0;
    font: normal normal normal 22px/28px 'Roboto';
  }
`;

export const PhoneContainer = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 8px;
  display: flex;

  @media (max-width: ${TABLET_992}px) {
    display: inline;
  }
`;

export const PhoneText = styled.p`
  display: inline;
  color: ${COLORS.redTitle};
  font: normal normal 600 22px/26px 'Segoe UI';
`;