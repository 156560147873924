import styled from "styled-components";
import { DESKTOP_1366, MOBILE_460, TABLET_600, TABLET_768, TABLET_992 } from "../../../styles/globals/sizes";

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 70px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  flex-wrap: wrap;
  gap: 30px;
  
  @media (max-width: ${DESKTOP_1366}px) {
    width: 92%;
  }
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    padding: 50px 20px;
  }
`;

export const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  column-gap: 42px;

  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`;

export const LinkWrapper = styled.a`
  width: 47%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 28px/68px 'Roboto';
  color: #404040;
  border-bottom: 1px solid #888888;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }

  &:hover {
    color: #E0281A;
    font-weight: bold;
    svg {
      path {
        fill: #E0281A;
      }
    }
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const ArrowContainer = styled.div`
  width: 16px;
  height: 16px;
`;

export const Title = styled.h2`
  width: 100%;
  font: normal normal bold 37px/49px 'Roboto';
  color: #E0281A;

  @media (max-width: ${TABLET_768}px) {
    width: 49%;
    margin: 0 auto;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
  }
  @media (max-width: ${TABLET_600}px) {
    width: 64%;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 85%;
  }
`;

export const Subtitle = styled.p`
  max-width: 930px;
  font: normal normal normal 20px/28px 'Roboto';
  color: #404040;

  @media (max-width: ${TABLET_768}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
