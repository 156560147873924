import React from "react";
import { graphql } from "gatsby";

import seoImage from "../../static/seo/seo_image.png";

import Seoinfo from "./seoinfo/seoinfo";
import Layout from "./layout";
import Hero from "./County/Hero/index";
import transformToSlug from "./utils";
import TownshipIndex from "./County/TownshipIndex/index";
import ContactUs from "./ContactUs/ContactUs";

const CountyTemplate = ({ data }: any) => {
  const CountyData = data?.cms?.locationsCountyPage?.data?.attributes;
  const Townships = CountyData?.locations_townships?.data;
  const CountyUrl = `/${transformToSlug(CountyData.locations_county?.data?.attributes?.County)}`;

  return (
    <main>
      <Layout>
        <Seoinfo
            siteName={`Explore Life Homecare in ${CountyData.Hero_Title} for Quality Services`}
            titlePage={`Explore Life Homecare in ${CountyData.Hero_Title} for Quality Services`}
            description={`Discover top-notch Life Homecare services in ${CountyData.Hero_Title}. Get the care you deserve today!`}
            keywords={"Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"}
            type={"website"}
            url={CountyUrl}
            imageFacebook={seoImage}
          />
          <Hero title={CountyData.Hero_Title}/>
          <TownshipIndex subtitle={CountyData.Township_Subtitle} townships={Townships} countyUrl={CountyUrl}/>
          <ContactUs />
      </Layout>
    </main>
  );
};

export const pageQuery = graphql`
  query($id: ID!) {
    cms {
        locationsCountyPage(id: $id) {
        data {
          attributes {
            Hero_Title
            Township_Subtitle
            locations_county {
              data {
                attributes {
                  County
                }
              }
            }
            locations_townships(pagination: {limit: -1}) {
              data {
                attributes {
                  Township
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CountyTemplate;
